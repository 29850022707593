import { Link } from 'gatsby'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import IconUser from '../images/icon-user.png'
import IconLock from '../images/icon-lock.png'
import IconCasinoLive from '../images/icon-casino-live.png'
import IconCasino from '../images/icon-casino.png'
import IconSport from '../images/icon-sport.png'
import IconGame from '../images/icon-game.png'
import IconApp from '../images/icon-app.png'
import IconPromotion from '../images/icon-promotion.png'

const Logo = styled.figure`
  width: 200px;
`

const HeaderStyle = styled.header`
  padding-top: 69px;
  @media only screen and (min-width: 1024px) {
    padding-top: 74px;
  }
`

const UserInput = styled.div`
  position: relative;
  margin-right: 0.5rem;
  input {
    background: #ffbe00;
    padding: 0.5rem;
    padding-left: 2.5rem;
    color: #000;
    border-radius: 0.25rem;
    max-width: 180px;
    ::placeholder {
      color: #666;
    }
  }
  img {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 1;
  }
`
const PasswordInput = styled.div`
  position: relative;
  margin-right: 0.5rem;
  input {
    background: #ffbe00;
    padding: 0.5rem;
    padding-left: 2.5rem;
    color: #000;
    border-radius: 0.25rem;
    max-width: 180px;
    ::placeholder {
      color: #666;
    }
  }
  img {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 1;
  }
`

const ButtonPrimary = styled.button`
  border: 1px solid #ffbe00;
  padding: 0.5rem;
  transition: all 0.3s ease;
  :hover {
    background: #ffbe00;
    color: #fff;
  }
  &.blink {
    -webkit-animation: blink 1s infinite;
    -moz-animation: blink 1s infinite;
    -o-animation: blink 1s infinite;
    animation: blink 1s infinite;
    transition: all 0.3s ease;
  }
  @keyframes blink {
    0%,
    49% {
      background-color: #000;
      color: #fff;
    }
    50%,
    100% {
      background-color: #ffbe00;
      color: #000;
    }
  }
  @-webkit-keyframes blink {
    0%,
    49% {
      background-color: #000;
      color: #fff;
    }
    50%,
    100% {
      background-color: #ffbe00;
      color: #000;
    }
  }
`

const MainMenu = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      :not(:last-child) {
        margin-right: 0.5rem;
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 2rem;
        transition: all 0.3s ease;
        :hover {
          background: rgba(255, 190, 0, 0.7);
        }
        img {
          margin-bottom: 0.25rem;
          max-width: 32px;
          max-height: 32px;
        }
        img + span {
          font-size: 0.75rem;
        }
      }
    }
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconLogo: file(relativePath: { eq: "icon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <HeaderStyle className="bg-black">
        <div
          className="bg-black py-3 fixed top-0 left-0 z-10 w-full"
          style={{ borderBottom: '1px solid #ffbe00' }}
        >
          <div className="container mx-auto px-3">
            <div className="flex justify-between">
              <div className="flex items-center lg:hidden">
                <button type="button" className="px-2 mr-2" aria-label="menu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="32"
                    width="32"
                    viewBox="0 -53 384 384"
                  >
                    <g>
                      <path
                        d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                        data-original="#000000"
                        className="active-path"
                        data-old_color="#000000"
                        fill="#FFBE00"
                      />
                      <path
                        d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                        data-original="#000000"
                        className="active-path"
                        data-old_color="#000000"
                        fill="#FFBE00"
                      />
                      <path
                        d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                        data-original="#000000"
                        className="active-path"
                        data-old_color="#000000"
                        fill="#FFBE00"
                      />
                    </g>{' '}
                  </svg>
                </button>
                <Link to="/">
                  <Img
                    fluid={data.iconLogo.childImageSharp.fluid}
                    style={{ width: 44, height: 44 }}
                  />
                </Link>
              </div>
              <Link to="/" className="hidden lg:block">
                <Logo>
                  <Img fluid={data.logo.childImageSharp.fluid} />
                </Logo>
              </Link>
              <div>
                <form className="flex items-center" method="post">
                  <UserInput className="hidden lg:block">
                    <img src={IconUser} alt="User" />
                    <input
                      type="text"
                      placeholder="อีเมลล์ หรือ ไอดี"
                      aria-label="user"
                    />
                  </UserInput>
                  <PasswordInput className="hidden lg:block">
                    <img src={IconLock} alt="Password" />
                    <input
                      type="text"
                      placeholder="รหัสผ่าน"
                      aria-label="password"
                    />
                  </PasswordInput>
                  <ButtonPrimary className="order-4 lg:order-3 lg:mr-2">
                    เข้าสู่ระบบ
                  </ButtonPrimary>
                  <ButtonPrimary className="blink order-3 lg:order-4 mr-2">
                    สมัครสมาชิก
                  </ButtonPrimary>
                  <ButtonPrimary className="hidden lg:block order-5">
                    TH / EN
                  </ButtonPrimary>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto hidden lg:block">
          <MainMenu>
            <ul>
              <li>
                <Link to="/">
                  <img src={IconCasinoLive} alt="คาสิโนสด" />
                  <span>คาสิโนสด</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconCasino} alt="คาสิโน" />
                  <span>คาสิโน</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconSport} alt="กีฬา" />
                  <span>กีฬา</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconGame} alt="เกม" />
                  <span>เกม</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconApp} alt="แอป" />
                  <span>แอป</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconPromotion} alt="โปรโมชั่น" />
                  <span>โปรโมชั่น</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span>เกี่ยวกับเรา</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span>ติดต่อเรา</span>
                </Link>
              </li>
            </ul>
          </MainMenu>
        </div>
      </HeaderStyle>
    </>
  )
}

export default Header
