import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import IconDanger from '../images/danger.png'

const FooterStyle = styled.footer`
  color: #c9c9c9;
  background: #242424;
  padding: 1rem;
  font-weight: 300;
`

const MenuFooter = styled.ul`
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    :not(:last-child) {
      ::after {
        content: '|';
        margin: 0 1rem;
      }
    }
  }
`

const Footer = () => {
  return (
    <FooterStyle>
      <div className="container mx-auto text-sm lg:text-base">
        <p className="flex items-center mb-3">
          <img
            src={IconDanger}
            alt="18+"
            className="mr-2"
            style={{ maxWidth: 24 }}
          />{' '}
          เล่นอย่างมีความรับผิดชอบ
        </p>
        <MenuFooter>
          <li>
            <Link to="/">การเล่นเกมอย่างรับผิดชอบ</Link>
          </li>
          <li>
            <Link to="/">ข้อกำหนดและเงื่อนไข</Link>
          </li>
          <li>
            <Link to="/">ประกาศความเป็นส่วนตัว</Link>
          </li>
          <li>
            <Link to="/">ข้อมูลความปลอดภัย</Link>
          </li>
          <li>
            <Link to="/">ช่วยเหลือ</Link>
          </li>
        </MenuFooter>
        <p className="mb-3">
          "MEBET" เป็นเครื่องหมายการค้าจดทะเบียน MEBET
          ไม่มีส่วนเกี่ยวข้องหรือเชื่อมต่อกับทีมกีฬาผู้จัดงานหรือผู้เล่นที่แสดงในเว็บไซต์
          MEBET ไม่มีส่วนเกี่ยวข้องหรือเชื่อมต่อกับแบรนด์มือถือใด ๆ
        </p>
        <p className="mb-3">
          เว็บไซต์นี้ดำเนินการโดย Mebet International Ltd ซึ่งมีการลงทะเบียน
          office คือ Level 4 - The Centre, Malta
        </p>
        <p className="mb-3">
          หมายเลขและวันที่ออกใบอนุญาตอย่างเป็นทางการคือ MGA / B2C / 106/2000
          ออกให้เมื่อวันที่ 1 สิงหาคม 2018 มีผลจนถึงวันที่ 17 กรกฎาคม 2571 Mebet
          International Ltd ได้รับอนุญาตจาก Sour Sugar Gaming (SSG)
          หน่วยงานกำกับดูแลสาธารณะนี้มีหน้าที่รับผิดชอบในการกำกับดูแลทุกรูปแบบ
          ของเกมอยู่ในมอลตา ค้นหาเพิ่มเติมเกี่ยวกับ SSG ที่{' '}
          <a href="https://www.membersg.com" target="_blank" rel="noreferrer">
            www.membersg.com
          </a>
        </p>
      </div>
      <div className="text-center">
        <span className="text-xs">
          Copyright {new Date().getFullYear()}, mebet.com all rights reserved.
        </span>
      </div>
    </FooterStyle>
  )
}

export default Footer
